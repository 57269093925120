import React from "react"
import { Link } from "gatsby"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Layout from "../Layout/layout"
import Seo from "../seo"

const SettingsLayout = ({ children, title, iconClass, isBusinessPage }) => {
  return (
    <Layout>
      <Seo title={`Settings - ${title}`} />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col-sm-12">
            <div className="pageTitle_Editor pb-3">
              <Link
                to={`/settings/${isBusinessPage === "1" ? "business" : ""}`}
                className="goBack"
                data-bs-toggle="tooltip"
                title="Back to Settings"
              >
                <i className="bi bi-arrow-90deg-left" />
              </Link>
              <button type="button" className="CD_UserType isHuman">
                <i className={`bi bi-${iconClass}`}></i>
              </button>
              <input type="text" className="form-control" readOnly="" defaultValue={title} />
            </div>
          </div>
          <React.Fragment>{children}</React.Fragment>
          {/*<div className="row g-0 pt-4">
            <div className="col-lg-12 text-center">
              <button className="btn btn-primary btn-lg">
                <i className="bi bi-check2" /> Save
              </button>
            </div>
          </div>*/}
        </div>
      </div>
    </Layout>
  )
}

export default SettingsLayout
